<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-if="!isLoading">
      <div class="flex mb-4 justify-between">
          <router-link
          v-if="vacancy.link_extern_option"
          class="bg-green-1000 text-white px-6 py-3 rounded rounded-md focus:outline-none"
          :to="{ name: 'list-vacancies' }"
        >
          <svg
            style="display: initial;"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
          Voltar
        </router-link>
        <router-link
          v-else
          class="bg-green-1000 text-white px-6 py-3 rounded rounded-md focus:outline-none"
          :to="{ name: 'schedule-interview' }"
        >
        <svg
          style="display: initial;"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          />
        </svg>
          Voltar
        </router-link>
      </div>
      
      <div class="p-4 flex">
        <h1 class="text-3xl">Candidatos Aprovados: {{ vacancy.title }}</h1>
      </div>

      <!-- STEPS -->
      <div class="w-full py-6">
        <div class="flex">
          <div class="w-1/4">
            <div class="relative mb-2">
              <div class="w-10 h-10 mx-auto bg-green-600 rounded-full text-lg text-white flex items-center">
                <span class="text-center text-white w-full">
                  <!-- <h1 class="text-white">1°</h1> -->
                  <i class="ri-file-list-line"></i>
                </span>
              </div>
            </div>
            <div class="text-xs text-center md:text-base">Em inscrição</div>
          </div>

          <div class="w-1/4">
            <div class="relative mb-2">
              <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
                <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                  <div class="w-0 bg-green-500 py-1 rounded" style="width: 100%;"></div>
                </div>
              </div>

              <div class="w-10 h-10 mx-auto bg-green-600 rounded-full text-lg text-white flex items-center">
                <span class="text-center text-white w-full">
                  <svg class="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/>
                    <path d="M12 14v2a6 6 0 0 0-6 6H4a8 8 0 0 1 8-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm6 10.5l-2.939 1.545.561-3.272-2.377-2.318 3.286-.478L18 14l1.47 2.977 3.285.478-2.377 2.318.56 3.272L18 21.5z" fill="rgba(255,255,255,1)"/>
                  </svg>       
                </span>
              </div>
            </div>

            <div class="text-xs text-center md:text-base">Em Seleção</div>
          </div>

          <div class="w-1/4">
            <div class="relative mb-2">
              <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
                <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                  <div class="w-0 bg-green-500 py-1 rounded" style="width: 100%;"></div>
                </div>
              </div>

              <div class="w-10 h-10 mx-auto bg-green-600 rounded-full text-lg text-white flex items-center">
                <span class="text-center text-gray-600 w-full">
                  <svg class="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z" fill="rgba(255,255,255,1)"/>
                  </svg>
                </span>
              </div>
            </div>

            <div class="text-xs text-center md:text-base">Agendar entrevista</div>
          </div>

          <div class="w-1/4">
            <div class="relative mb-2">
              <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
                <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                  <div class="w-0 bg-green-500 py-1 rounded" style="width: 100%;"></div>
                </div>
              </div>

              <div class="w-10 h-10 mx-auto bg-green-600 rounded-full text-lg text-white flex items-center">
                <span class="text-center text-gray-600 w-full">
                  <svg class="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" fill="rgba(255,255,255,1)"/>
                  </svg>
                </span>
              </div>
            </div>

            <div class="text-xs text-center md:text-base">Candidatos aprovados</div>
          </div>
        </div>
      </div>

      <div class="p-4">
        <v-client-table
          ref="table"
          :data="participants"
          :columns="columns"
          :options="options"
        >
          <div
            class="flex justify-center items-center"
            slot="resume"
            slot-scope="{ row }"
          >
            <router-link
              class="mr-3 text-2xl border-2 border-yellow-500 hover:bg-yellow-500 text-yellow-500 hover:text-white p-1 rounded-lg focus:outline-none focus:shadow-outline"
              :to="{ name: 'curriculum', params: { id: row.id } }"
              v-tooltip="{ content: 'Ver Currículo', placement: 'left' }"
              target="_blank"
            >
              <i class="ri-article-line"></i>
            </router-link>
          </div>
          <div
            class="flex justify-center items-center"
            slot="salary"
            slot-scope="{ row }"
          >
            <i
              class="ri-information-line mr-2"
              v-tooltip="{ content: 'Clique para alterar', placement: 'left' }"
            ></i>
            <input
              type="text"
              id="salary"
              v-model.lazy="row.salary"
              v-money="money"
              @focusout="updateSalary(row.id, row.salary)"
            />
          </div>
          <div
            class="flex justify-center items-center"
            slot="start_activities"
            slot-scope="{ row }"
          >
            <i
              class="ri-information-line mr-2"
              v-tooltip="{ content: 'Clique para alterar', placement: 'left' }"
            ></i>
            <input
              type="date"
              id="start_activities"
              v-model="row.start_activities"
              @change="updateActivities(row.id, row.start_activities)"
            />
          </div>
          <div
            class="flex justify-end"
            slot="actions"
            slot-scope="{ row, index }"
          >
            <input
              type="checkbox"
              v-model="participantsAproved"
              @click="checkParticipant(row, index)"
              v-tooltip="{
                content: 'Selecione apenas os talentos que foram contratados.',
                placement: 'right'
              }"
              :value="row.id"
              v-if="row.status != 'Aprovado(a)'"
            />
          </div>
        </v-client-table>
        <div class="flex mb-4 justify-end">
          <button
            class="bg-green-1000 text-white px-6 py-3 rounded rounded-md focus:outline-none"
            @click="finalizingVacancy()"
            v-if="vacancy.status != 'Finalizado'"
          >
          <i class="ri-check-fill mr-1"></i>
            Finalizar
          </button>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import axios from '@/utils/axios';
import { VMoney } from 'v-money';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';

export default {
  name: 'FinalizingVacancy',

  title() {
    return `${process.env.VUE_APP_NAME} | Finalização da vaga`;
  },

  directives: { money: VMoney },

  components: {
    DashboardLayout,
    LoaderAnimation
  },

  props: {
    id: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      vacancy: {
        status: '',
        salary: '',
        avaliable: '',
        start_activities: '',
        link_extern_option: ''
      },
      participantsAproved: [],
      participants: [],
      participantsSalariesStartActivities: [],
      activities: [],

      participantsExternal: {
        name: '',
        vacancy: null
      },

      columns: [
        'id',
        'actions',
        'name',
        'salary',
        'start_activities',
        'status',
        'resume'
      ],
      options: {
        sortable: true,
        perPage: 10,
        perPageValues: [10, 15, 20, 25, 50],
        headings: {
          name: 'Nome',
          salary: 'Sálario',
          start_activities: 'Ini. das Atividades',
          document: 'E-mail',
          resume: 'Currículo',
          actions: '',
          status: 'Status'
        },
        hiddenColumns: ['id'],
        sortIcon: {
          is: 'glyphicon-sort',
          base: 'glyphicon',
          up: 'glyphicon-chevron-up',
          down: 'glyphicon-chevron-down'
        },
        texts: {
          first: 'Primeiro',
          last: 'Último',
          filter: 'Filtrar:',
          filterBy: 'Filtrar por {column}',
          filterPlaceholder: 'Pesquise aqui...',
          count:
            '{from} - {to} de {count} registros|{count} registros|Um registro',
          limit: 'Registros por página:',
          page: 'Página:',
          noResults: 'Nenhum talento encontrado. Talvez você tenha esquecido de entrevistar o cantidato na tela anterior.',
          columns: 'Colunas'
        },
        pagination: {
          chunk: 5
        }
      },

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false /* doesn't work with directive */
      }
    };
  },

  methods: {
    fecthParticipants() {
      this.isLoading = true;
      axios
        .get(`/api/participante/entrevistados/${this.$route.params.id}`)
        .then(({ data }) => {
          data.participants.forEach(participant => {
            this.participants.push(participant);
          });
          this.isLoading = false;
        });
    },

    updateSalary(id, salary) {
      this.participantsSalariesStartActivities.forEach(row => {
        if (row.id == id) {
          row.salary = salary;
        }
      });
    },

    updateActivities(id, start_activities) {
      this.participantsSalariesStartActivities.forEach(row => {
        if (row.id == id) {
          row.start_activities = start_activities;
        }
      });
    },

    nextStepAproved() {
      const participantsVacancy = {
        participants: [],
        vacancy: []
      };

      if (this.participantsSalariesStartActivities.length == 0) {
        this.participantsAproved.forEach(id => {
          participantsVacancy.participants.push({
            id: id,
            salary: this.vacancySalary,
            start_activities: this.start_activities
          });
        });
      } else {
        this.participantsAproved.forEach((id, index) => {
          participantsVacancy.participants.push({
            id: id,
            salary:
              this.participantsSalariesStartActivities[index].id == id
                ? this.participantsSalariesStartActivities[index].salary
                : this.vacancySalary,
            start_activities:
              this.participantsSalariesStartActivities[index]
                .start_activities ?? this.start_activities
          });
        });
      }

      participantsVacancy.vacancy.push(this.$route.params.id);
      axios
        .post('/api/participante/aprovar-participante', participantsVacancy)
        .then(({ data }) => {
          this.$toast.success(data.data.message);

          axios.put(`/api/vagas/status/${this.$route.params.id}`, {
            status: 4
          });
        })
        .catch(({ response }) => {
          Object.values(response.data.errors).forEach(error => {
            this.$toast.error(...error);
          });
        });
    },

    finalizingVacancy() {
      let checked = false;
      Array.from(this.participants).forEach(element => {
        if (element.check == true) {
          checked = true;
        }
      });

      if (!checked) {
        this.$confirm({
          message: 'Realmente nenhum contratado foi da colheita?',
          button: {
            no: 'Não',
            yes: 'Sim'
          },
          callback: confirm => {
            if (confirm) {
              const participantsExternal = {
                participants: [],
                vacancy: []
              };
              for (let index = 0; index < this.vacancy.avaliable; index++) {
                participantsExternal.participants[index] = prompt(
                  'Nome do Participante'
                );
              }
              participantsExternal.vacancy.push(this.$route.params.id);

              axios
                .post(
                  '/api/participante/participante-externo',
                  participantsExternal
                )
                .then(({ data }) => {
                  this.$toast.success(data.data.message);

                  axios
                    .put(`/api/vagas/status/${this.$route.params.id}`, {
                      status: 4
                    })
                    .then(() => {
                      this.$toast.success('Vaga Finalizada com Sucesso!');
                      setTimeout(() => {
                        this.$router.push({ name: 'list-vacancies' });
                      }, 2000);
                    });
                });
            }
          }
        });
      } else {
        this.$confirm({
          message: `Salário: ${
            this.vacancy.salary
          } e Data de início da atividade: ${this.formatData(
            this.vacancy.start_activities
          )}`,
          button: {
            no: 'Não',
            yes: 'Sim'
          },
          callback: confirm => {
            if (confirm) {
              this.nextStepAproved();
              this.$toast.success('Vaga Finalizada com Sucesso!');
            }
            setTimeout(() => {
              this.$router.push({ name: 'list-vacancies' });
            }, 2000);
          }
        });
      }
    },

    checkParticipant(row, index) {
      this.participants[index - 1].check = !this.participants[index - 1].check;

      this.participantsSalariesStartActivities.push({
        id: row.id,
        salary: row.salary,
        start_activities: row.start_activities
      });
    },

    formatData(value) {
      let data;

      if (value != null) {
        data = new Date(value);
        data = data.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
      } else {
        data = 'Não informado.';
      }

      return data;
    },

    fetchVacancy() {
      axios
        .get(`/api/vagas/${this.$route.params.id}/vaga/`)
        .then(({ data }) => {
          console.log(data);
          this.vacancy.title = data.data[0].office;
          this.vacancy.status = data.data[0].status;
          this.vacancy.salary = data.data[0].salary;
          this.vacancy.avaliable = data.data[0].vacancies_available;
          this.vacancy.start_activities = data.data[0].start_activities;
          this.vacancy.link_extern_option = data.data[0].link_extern_option;
        });
    }
  },

  created: function() {
    this.fetchVacancy();
    this.fecthParticipants();
  }
};
</script>

<style scoped></style>
